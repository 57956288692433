import { useApolloClient } from '@apollo/client';

const withAuthProvider = () => {
  const apolloClient = useApolloClient();

  return {
    login: async () => {
      console.log('login');
      return true;
    },
    logout: async () => {
      localStorage.removeItem('user');
      await apolloClient.resetStore();
      return Promise.resolve();
    },
    checkError: (error) => {
      const status = error.status;
      console.log(status, error.message);
      if (
        status === 401 ||
        status === 403 ||
        error.message.includes('Not Authorised!') ||
        error.message.includes('An error occurred!')
      ) {
        document.cookie = '';
        localStorage.removeItem('user');
        return Promise.reject({ redirectTo: '/login' });
      }
      console.log('aaa');
      // other error code (404, 500, etc): no need to log out
      return Promise.resolve();
    },
    checkAuth: async () => {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      console.log('user', !user?.id);
      if (!user?.id) {
        return Promise.reject({ redirectTo: '/login' });
      }
      return Promise.resolve(user);
    },
    getPermissions: () => {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      return Promise.resolve({
        is_admin: user?.is_admin,
        is_ldap: user?.is_ldap,
        is_superadmin: user?.is_superadmin,
      });
    },
    getIdentity: async () => {
      const user = JSON.parse(localStorage.getItem('user') || '{}');

      return Promise.resolve(user);
    },
  };
};

export default withAuthProvider;

import { defaultTheme } from 'react-admin';
import { lightTheme } from './libs/themes';

const customTheme = {
  ...defaultTheme,
  ...lightTheme,
  ...{
    palette: {
      secondary: {
        main: '#fff',
      },
    },
    MuiTableRow: {
      root: {
        height: 40,
      },
    },
  },
};

export default customTheme;

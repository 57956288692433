import './App.css';

import { Admin, CREATE, DELETE, Login, Resource, UPDATE } from 'react-admin';
import { EventCreate, EventEdit, EventsList } from './modules/events';
import { ExpenseCreate, ExpenseEdit, ExpensesList } from './modules/expenses';
import { User, UserCreate, UserEdit, UsersList } from './modules/users';
import styled, { createGlobalStyle } from 'styled-components';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { CircularProgress } from '@material-ui/core';
import CustomLayout from './CustomLayout';
import CustomTheme from './CustomTheme';
import LoginForm from './modules/login';
import { Options } from '@ra-data-prisma/dataprovider';
import { Resource as ResourceDataprovider } from '@ra-data-prisma/dataprovider/lib/constants/interfaces';
import { appProvidersFactory } from './appProvidersFactory';
import i18nProvider from './locales';
import { makePrefixedFullName } from '@ra-data-prisma/dataprovider/lib/utils/makePrefixedFullName';
import schema from './graphql-schema.json';

const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const SpinnerWrapper = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
`;

const App: React.VFC<{ client: any }> = ({ client }) => {
  const options: Options = {
    client,
    introspection: {
      schema: (schema as any).__schema,
    },
    queryDialect: 'typegraphql',
    resourceViews: {
      User,
    },
    mutationOperationNames: {
      typegraphql: {
        [CREATE]: (resource: ResourceDataprovider) =>
          makePrefixedFullName(`createOne${resource.name}`),
        [UPDATE]: (resource: ResourceDataprovider) =>
          makePrefixedFullName(`updateOne${resource.name}`),
        [DELETE]: (resource: ResourceDataprovider) =>
          makePrefixedFullName(`deleteOne${resource.name}`),
      },
    },
  };

  const { dataProvider, authProvider } = appProvidersFactory()(options);

  if (!dataProvider) {
    return (
      <Center>
        <SpinnerWrapper />
        <CircularProgress />
      </Center>
    );
  }

  return (
    <Admin
      dataProvider={dataProvider}
      disableTelemetry={true}
      i18nProvider={i18nProvider}
      layout={CustomLayout}
      theme={CustomTheme}
      loginPage={(props) => (
        <Login {...props}>
          <LoginForm />
        </Login>
      )}
      authProvider={authProvider}>
      <Resource
        name="User"
        options={{ label: 'Uživatelé' }}
        list={UsersList}
        edit={UserEdit}
        create={UserCreate}
        icon={AssignmentIndIcon}
      />
      <Resource
        name="Event"
        options={{ label: 'Události' }}
        list={EventsList}
        edit={EventEdit}
        create={EventCreate}
        icon={AssignmentIndIcon}
      />
      <Resource
        name="Expense"
        options={{ label: 'Výdaje' }}
        list={ExpensesList}
        edit={ExpenseEdit}
        create={ExpenseCreate}
        icon={AssignmentIndIcon}
      />
      <Resource name="Wallet" />
      <Resource name="UserAttendance" />
    </Admin>
  );
};

export default App;

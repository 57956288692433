import {
  CheckboxGroupInput,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

import CustomPagination from '../CustomPagination';
import { Grid } from '@mui/material';
import {
  ReferenceManyToManyInput,
} from '@react-admin/ra-relationships';

const EventForm = () => {
  return (
    <Grid container spacing={8}>
      <Grid item md={6}>
        <TextInput source="name" label="Název" />
        <br />
        <NumberInput source="expense" label="Výdaj v korunách" />
        <br />
      </Grid>
      <Grid item md={6}>
        <ReferenceManyToManyInput
          reference="User"
          through="UserAttendace"
          using="event_id,user_id"
          label="Docházka"
          perPage={100}
          perPageChoices={100}
          sortChoices={{ field: 'firstName', order: 'ASC' }}>
          <CheckboxGroupInput
            row={false}
            source='user_id'
            label="Hráč"
            optionText={(row) => `${row.firstName} ${row.lastName}`}
          />
        </ReferenceManyToManyInput>
      </Grid>
    </Grid>
  );
};

const transform = (data: any) => {
  data.userAttendace = data['@@ra-many-to-many/Event/UserAttendace/User'] || [];
  return data;
};

export const EventCreate = (props: any) => (
  <Create
    {...props}
    undoable={false}
    mutationMode="pessimistic"
    transform={transform}>
    <SimpleForm>
      <EventForm />
    </SimpleForm>
  </Create>
);

export const EventEdit = (props: any) => (
  <Edit
    {...props}
    undoable={false}
    mutationMode="pessimistic"
    transform={transform}>
    <SimpleForm>
      <EventForm />
    </SimpleForm>
  </Edit>
);

export const EventFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="name" label="Název" />
  </Filter>
);

export const EventsList = (props: any) => {
  return (
    <List
      {...props}
      filters={<EventFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="name" label="Název" />
        <FunctionField label="Počet hráčů" render={(row) => row.users?.length} />
        <DateField source="createdAt" label="Vytvořeno" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

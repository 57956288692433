import {
  BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  List,
  NullableBooleanInput,
  NumberInput,
  PasswordInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  TopToolbar,
  downloadCSV,
  email,
  required,
  useListContext,
  useRecordContext,
} from 'react-admin';
import { gql, useQuery } from '@apollo/client';
import { sum, sumBy } from 'lodash';

import CustomPagination from '../CustomPagination';
import { Grid } from '@mui/material';
import { ReferenceManyInput } from '@react-admin/ra-relationships';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { cloneElement } from 'react';
import jsonExport from 'jsonexport/dist';

const UserForm = () => {
  const validateEmail = email();
  const record = useRecordContext();

  return (
    <Grid container spacing={8}>
      <Grid item md={6}>
        {!record?.id && (
          <>
            <TextInput
              type="email"
              source="email"
              inputProps={{ autoComplete: 'off' }}
              autoComplete="off"
              validate={validateEmail}
              style={{ width: 250 }}
            />
            <br />
            <PasswordInput
              inputProps={{ autoComplete: 'off' }}
              source="password"
              style={{ width: 250 }}
            />
            <br />
          </>
        )}
        <TextInput type="text" source="firstName" style={{ width: 250 }} />
        <br />
        <TextInput type="text" source="lastName" style={{ width: 250 }} />
        {!record?.id && <BooleanInput source="isAdmin" />}
      </Grid>

      {record?.id && (
        <Grid item md={6}>
          Wallet
          <ReferenceManyInput reference="Wallet" target="user_id">
            <SimpleFormIterator inline>
              <NumberInput source="amount" validate={[required()]} />
              <DateInput source="createdAt" defaultValue={new Date()} />
            </SimpleFormIterator>
          </ReferenceManyInput>
        </Grid>
      )}
    </Grid>
  );
};

export const UserCreate = (props: any) => (
  <Create {...props} undoable={false} mutationMode="pessimistic">
    <SimpleForm>
      <UserForm />
    </SimpleForm>
  </Create>
);

export const UserEdit = (props: any) => (
  <Edit {...props} undoable={false} mutationMode="pessimistic">
    <SimpleForm>
      <UserForm />
    </SimpleForm>
  </Edit>
);

export const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="email" />
    <NullableBooleanInput source="isAdmin" />
  </Filter>
);

const EXPENSES_QUERY = gql`
  query ExpensiesQuery {
    expenses(where: { deletedAt: null }) {
      id
      name
      amount
      splitCount
    }
  }
`;

const customExporter = (items, expenses) => {
  const usersCount = items.length;

  const listForExport = items.map((item) => {
    const userBudget = sumBy(item.wallets, 'amount') || 0;
    const attendanceExpense = items.attendaces?.map(
      (attendance) =>
        attendance?.event?.expense / attendance?.event?.users?.length,
    );

    const sumExpensesAmount = sumBy(expenses, 'amount');

    const budget = `${Math.round(
      userBudget - sum(attendanceExpense) - sumExpensesAmount / usersCount,
    )} Kč`;

    return {
      Jméno: `${item.firstName} ${item.lastName}`,
      'Počet odehraných her': item.attendaces?.length,
      Zaplaceno: `${sumBy(item.wallets, 'amount') || 0} Kč`,
      'V budgetu': budget,
    };
  });

  jsonExport(listForExport, { rowDelimiter: ';' }, (err, csv) =>
    downloadCSV(csv, 'Items'),
  );
};

const ListActions = (props) => (
  <TopToolbar>
    {cloneElement(props.filters, { context: 'button' })}
    <CreateButton />
    <ExportButton maxResults={10000000} />
  </TopToolbar>
);


export const UsersList = (props: any) => {
  const { data } = useQuery(EXPENSES_QUERY);
  const expenses = data?.expenses || [];
  return (
    <List
      {...props}
      exporter={(items) => customExporter(items, expenses)}
      filters={<UserFilter />}
      actions={<ListActions />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      // sort={{ field: 'firstName.sort', order: 'ASC' }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <FunctionField
          source="firstName"
          label="Uživatel"
          // sortBy="firstName.sort"
          render={(row) => `${row.firstName} ${row.lastName}`}
        />
        <FunctionField
          label="Počet odehraných her"
          render={(row) => row.attendaces?.length}
        />
        {/* <BooleanField source="isAdmin" /> */}
        <FunctionField
          label="Zaplaceno"
          render={(row) => `${sumBy(row.wallets, 'amount') || 0} Kč`}
        />
        <FunctionField
          label="V budgetu"
          render={(row) => {
            const { data } = useListContext();
            const usersCount = data.length;
            const userBudget = sumBy(row.wallets, 'amount') || 0;
            const attendanceExpense = row.attendaces.map(
              (attendance) =>
                attendance?.event?.expense / attendance?.event?.users?.length,
            );

            const sumExpensesAmount = sumBy(expenses, 'amount');

            console.log(`${row.firstName} ${row.lastName} - `, usersCount, userBudget, attendanceExpense, sumExpensesAmount, expenses);

            return `${Math.round(
              userBudget -
              sum(attendanceExpense) -
              sumExpensesAmount / usersCount,
            )} Kč`;
          }}
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const fragmentList = gql`
  fragment UserFragment on User {
    wallets {
      id
      amount
    }
    attendaces {
      id
      event {
        id
        name
        expense
        users {
          id
        }
      }
    }
  }
`;

export const User: ResourceView = {
  resource: 'User',
  fragment: {
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragmentList,
    },
  },
};
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

import CustomPagination from '../CustomPagination';
import { Grid } from '@mui/material';

const ExpenseForm = () => {
  return (
    <Grid container spacing={8}>
      <Grid item md={6}>
        <TextInput source="name" />
        <br />
        <NumberInput source="amount" helperText="Kč" />
        <br />
        <NumberInput source="splitCount" helperText="How many times split it" />
        <br />
      </Grid>
    </Grid>
  );
};

export const ExpenseCreate = (props: any) => (
  <Create {...props} undoable={false} mutationMode="pessimistic">
    <SimpleForm>
      <ExpenseForm />
    </SimpleForm>
  </Create>
);

export const ExpenseEdit = (props: any) => (
  <Edit {...props} undoable={false} mutationMode="pessimistic">
    <SimpleForm>
      <ExpenseForm />
    </SimpleForm>
  </Edit>
);

export const ExpenseFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="name" />
  </Filter>
);

export const ExpensesList = (props: any) => {
  return (
    <List
      {...props}
      filters={<ExpenseFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="name" />
        <NumberField source="splitCount" />
        <FunctionField label="Amount" render={(row) => `${row.amount}Kč`} />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
